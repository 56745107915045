import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import InbredsImg from '../images/inbreds.jpg'
import IdiotImg from '../images/idiot.jpg'

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <h1 class="text-6xl font-bold mb-20">Trapt sucks.</h1>

    <div>
      <p>follow me on twitter: <a class="underline" href="https://twitter.com/jamesmartinez">@jamesmartinez</a></p>
    </div>

  </Layout>
)

export default IndexPage
